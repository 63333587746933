<template>
    <div class="pc-container">
        <div class="login-wrap">
            <div class="left">
                <img class="login-img" src="@/assets/images/pc/login/login-img.png" />
                <div class="system-name">NEODEAL</div>
            </div>
            <div class="right">
                <div class="title">{{$t('Login.Register')}}</div>
                <div class="type">{{$t('Login.Type')}}</div>
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item prop="email">
                        <el-input v-model="form.email" :placeholder="$t('Login.Email')"></el-input>
                    </el-form-item>
                    <el-form-item prop="code">
                        <div class="code-box">
                            <el-input class="code-input" v-model="form.code" :placeholder="$t('Login.VerificationCode')"></el-input>
                            <el-button v-if="!isSend" :loading="isClick" class="send-btn" @click="sendCode">{{$t('Login.Send')}}</el-button>
                            <el-button v-else class="send-btn disabled-btn">{{second}} s</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item prop="phone">
                        <el-input v-model="form.phone" type="number" :placeholder="$t('Login.PhoneNumber')"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="form.password" show-password :placeholder="$t('Login.Password')"></el-input>
                    </el-form-item>
                    <el-form-item prop="confirmPassword">
                        <el-input v-model="form.confirmPassword" show-password :placeholder="$t('Login.ConfirmPassword')"></el-input>
                    </el-form-item>
                    <el-form-item prop="invite_code">
                        <el-input v-model="form.invite_code" :disabled="disabled" :placeholder="$t('Login.InvitationCode')"></el-input>
                    </el-form-item>
                    <el-form-item prop="checked">
                        <div class="agree">
                            <el-checkbox v-model="form.checked"></el-checkbox>
                            &nbsp; {{$t('Login.Agree')}}<span style="margin-left: 5px;color: #3973D1;cursor: pointer;" @click="openAlert">{{$t('Login.UserAgreement')}}</span>
                        </div>
                    </el-form-item>
                </el-form>
                <el-button :loading="loading" class="submit-btn" @click="submit">{{$t('Login.Register')}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            const checkPassword = (rule, value, callback) => {
                if (!value) {
                    callback(new Error(this.$t('Login.EnterConfirmPassword')))
                } else if (value != this.form.password) {
                    callback(new Error(this.$t('Login.EnterConfirmPasswordError')))
                } else {
                    callback()
                }
            }
            const checkedValid = (rule, value, callback) => {
                if (!value) {
                    callback(new Error(this.$t('Login.ConfirmAgreement')))
                } else {
                    callback()
                }
            }
            return {
                form: {
                    email: '',
                    code: '',
                    phone: '',
                    password: '',
                    confirmPassword: '',
                    invite_code: '',
                    checked: true
                },
                rules: {
                    email: [{
                            required: true,
                            message: this.$t('Login.EnterEmail'),
                            trigger: 'blur'
                        },
                        {
                            type: 'email',
                            message: this.$t('Login.EnterEmailError'),
                            trigger: 'submit'
                        }
                    ],
                    code: [{
                        required: true,
                        message: this.$t('Login.EnterVerificationCode'),
                        trigger: 'blur'
                    }],
                    phone: [{
                        required: true,
                        message: this.$t('Login.EnterPhoneNumber'),
                        trigger: 'blur'
                    }],
                    password: [{
                            required: true,
                            message: this.$t('Login.EnterPassword'),
                            trigger: 'blur'
                        },
                        {
                            min: 6,
                            max: 20,
                            message: this.$t('Login.EnterPasswordError'),
                            trigger: 'blur'
                        }
                    ],
                    confirmPassword: [{
                        validator: checkPassword,
                        trigger: 'blur'
                    }],
                    invite_code: [{
                        required: true,
                        message: this.$t('Login.EnterInvitationCode'),
                        trigger: 'blur'
                    }],
                    checked: [{
                        validator: checkedValid,
                        trigger: 'blur'
                    }]
                },
                loading: false,
                disabled: false,
                isSend: false,
                second: 300,
                timer: null,
                isClick: false
            }
        },
        created() {
            let query = this.$route.query
            this.form.email = query.email || ''
            if(query.invite_code) {
                this.form.invite_code = query.invite_code
                this.disabled = true
            }
        },
        destroyed() {
            // console.log('destroyed')
            clearInterval(this.timer)
        },
        methods: {
            openAlert() {
                this.$api.setting_lang({
                    slug: 'yhxy'
                },300000).then(res => {
                    this.$alert(res.data || '', this.$t('Login.UserAgreement'), {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: this.$t('Common.Confirm'),
                        confirmButtonClass: 'alert-confirm-btn',
                        center: true,
                        showClose: false,
                        callback: () => {
                            
                        }
                    })
                })
            },
            
            sendCode() {
            	if(this.form.email == "") {
            		this.$message({
            		    message: this.$t('Login.EnterEmailFirst'),
            		    type: 'warning',
            		    duration: 2000
            		})
            	} else {
                    this.isClick = true
            		this.$api.sendSms({
            			account: this.form.email
            		}).then(res => {
                        this.isSend = true
                        this.timer = setInterval(() => {
                        	this.second--
                        	if (this.second == 0) {
                        		this.second = 300
                        		this.isSend = false
                        		clearInterval(this.timer)
                        	}
                        }, 1000)
                        this.$message({
                            message: res.message,
                            type: 'success',
                            duration: 2000
                        })
                        this.isClick = false
                    }).catch(err => {
                        // console.log(err)
                        this.isClick = false
                    })
            	}
            },

            submit() {
                this.$refs.form.validate((valid) => {
                    // console.log(valid)
                    if(valid) {
                        this.loading = true
                        this.$api.register(this.form).then(res => {
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.$router.push({
                                        path: '/login'
                                    })
                                }
                            })
                            this.loading = false
                        }).catch(err => {
                            // console.log(err)
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 80px 0;
        background: url(~@/assets/images/pc/login/login-bg.png) no-repeat;
        background-size: 100% 100%;

        .login-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 680px;

            .left {
                position: relative;
                height: 100%;

                .login-img {
                    display: block;
                    height: 100%;
                }

                .system-name {
                    position: absolute;
                    top: 64px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 44px;
                    font-weight: bold;
                    color: #5466EF;
                    white-space: nowrap;
                }
            }

            .right {
                width: 400px;
                height: 100%;
                box-sizing: border-box;
                padding: 28px 32px;
                background-color: #FFFFFF;

                .title {
                    width: 100%;
                    margin-bottom: 28px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }

                .type {
                    width: 100%;
                    margin-bottom: 32px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000000;
                    word-wrap: break-word;
                }

                /deep/ .el-form-item {
                    margin-bottom: 22px;
                }

                /deep/ .el-input__inner {
                    height: 40px;
                    line-height: 40px;
                    padding: 0 16px;
                    border: 1px solid #D3D3D3;
                    font-size: 14px;
                    color: #000000;
                    background-color: #F5F5F5;
                }

                .code-box {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .code-input {
                        width: 100%;

                        /deep/ .el-input__inner {
                            border-right: 0;
                            border-radius: 4px 0 0 4px;
                        }
                    }

                    .send-btn {
                        height: 40px;
                        box-sizing: border-box;
                        padding: 0 16px;
                        border: 0;
                        border-radius: 4px;
                        font-size: 14px;
                        color: #FFFFFF;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #5466EF;
                        cursor: pointer;
                    }
                    
                    .disabled-btn {
                        color: #464646 !important;
                        background-color: #e2e2e2 !important;
                    }
                }

                .agree {
                    font-size: 13px;
                    color: #000000;
                    word-wrap: break-word;
                }
                
                /deep/ .el-checkbox__inner {
                    background-color: #5466EF !important;
                    border-color: #5466EF !important;
                }

                .submit-btn {
                    width: 100%;
                    height: 52px;
                    box-sizing: border-box;
                    margin-top: 16px;
                    padding: 0;
                    border: 0;
                    border-radius: 4px;
                    font-size: 15px;
                    color: #FFFFFF;
                    text-align: center;
                    background-color: #5466EF;
                    cursor: pointer;
                }
            }
        }
    }
</style>
